import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'

const vuexLocal = new VuexPersistence({
  storage: window.localStorage
})

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: null,
    idToken: null,
  },
  mutations: {
    login (state, auth) {
      state.idToken = auth.id_token
      state.token = auth.access_token
    },
    updateToken (state, token) {
      state.token = token
    },
    logout (state) {
      state.token = null
      state.idToken = null
    }
  },
  actions: {
  },
  modules: {
  },
  plugins: [vuexLocal.plugin]
})
