<template>
  <div id="app">
    <niias-navbar />
    <div class="inner">
      <router-view/>
    </div>
  </div>
</template>

<script>
import NiiasNavbar from './components/Navbar'

export default {
  components: { NiiasNavbar }
}
</script>