import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false
Vue.component('NationalSystem', () => import('./components/clinicalapps/NationalSystem.vue'))

// Buefy
import './style/Main.scss'
import Buefy from 'buefy'
Vue.use(Buefy)

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import * as fas from '@fortawesome/free-solid-svg-icons'
Vue.component('font-awesome-icon', FontAwesomeIcon)
library.add(
  fas.faExclamationTriangle, // warnings and errors
  fas.faExclamationCircle, // warnings only
  fas.faSignOutAlt // sign out
)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
