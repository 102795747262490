<template>
    <nav class="navbar container" role="navigation" aria-label="main navigation">
        <div class="navbar-brand">
            <span class="navbar-item">
                <img alt="NIIAS." src="../assets/niias_logo.png"/>
            </span>

            <a role="button" :class="`navbar-burger burger ${expanded?'is-active': ''}`" @click="expanded = !expanded" aria-label="menu" aria-expanded="false" data-target="navbarBasicExample">
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            </a>
        </div>

        <div id="navbarBasicExample" :class="`navbar-menu ${expanded?'is-active': ''}`">
            <div class="navbar-start">
              <!-- <router-link :to="{name: 'home'}" class="navbar-item">
                Home
              </router-link> -->
            </div>

            <div class="navbar-end">
              <div class="navbar-item has-tooltip-left" data-tooltip="Sign-out">
                <router-link :to="{name:'logout'}" class="is-light">
                  <font-awesome-icon icon="sign-out-alt"/>
                </router-link>
              </div>
            </div>
        </div>
    </nav>
</template>

<script>
export default {
  data () {
    return {
      expanded: false
    }
  }
}
</script>
